<template>
  <b-nav-item-dropdown
    class="navCartDropdownItem dropdown-cart mr-25"
    menu-class="dropdown-menu-media cartDropdownMenu"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="parseInt($store.state['app-ecommerce'].cartItemsCount, 10)"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Корзина
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.state['app-ecommerce'].cartItemsCount }} {{ declension(['Товар', 'Товара', 'Товаров'], $store.state['app-ecommerce'].cartItemsCount) }}
        </b-badge>
      </div>
    </li>

    <!-- Cart Items -->
    <vue-perfect-scrollbar
      v-if="items.length"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media
        v-for="item in items"
        :key="item.name"
      >
        <template #aside>
          <b-img
            :src="item.image ? server + item.image : server + '/views/products/0/no-image.png'"
            :alt="item.name"
            rounded
            width="62px"
          />
        </template>
        <feather-icon
          icon="XIcon"
          class="cart-item-remove cursor-pointer"
          @click.stop="removeProductFromCartAll(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link
              class="text-body"
              :to="{ name: 'shop-details', params: { id: item.id } }"
            >
              {{ item.name }}
            </b-link>
          </h6>
          <small class="cart-item-by">От {{ item.brand }}</small>
        </div>

        <div class="cart-item-qty ml-1">
          <b-form-spinbutton
            v-model="item.count"
            min="0"
            size="sm"
          >
            <template #decrement>
              <feather-icon
                icon="MinusIcon"
                @click="changeQtyItemInCart('minus', item)"
              />
            </template>
            <template #increment>
              <feather-icon
                icon="PlusIcon"
                @click="changeQtyItemInCart('plus', item)"
              />
            </template>
          </b-form-spinbutton>
          <div
            v-if="item.testModeComp && item.testModeComp === 'enable'"
            class="text-center testMode"
          >
            <small class="d-block">Взять на тест</small>
            <b-form-checkbox
              :checked="item.testMode === '1' ? true : false"
              name="testMode"
              switch
              inline
              size="sm"
              class="m-0 pb-0"
              @change="changeTestMode(item)"
            />
          </div>
        </div>

        <h5 class="cart-item-price">
          {{ item.price * item.count }} руб.
        </h5>
      </b-media>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li
      v-if="items.length"
      class="dropdown-menu-footer"
    >
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Всего:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ totalAmount }} руб.
        </h6>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :to="{ name: 'shop-checkout' }"
      >
        Перейти в корзину
      </b-button>
    </li>

    <p
      v-if="!items.length"
      class="m-0 p-1 text-center"
    >
      Ваша корзина пуста
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink,
  BImg, BFormSpinbutton, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { declension } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    BFormCheckbox,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount() {
      let total = 0
      this.items.forEach(i => {
        total += (parseInt(i.price, 10) * parseInt(i.count, 10))
      })
      return total
    },
  },
  methods: {
    fetchItems() {
      this.$store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          const { data } = response.data
          this.items = data.rows
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', data.paging[0].total)
        })
    },
    removeProductFromCart(product) {
      this.$store.dispatch('app-ecommerce/removeProductFromCart', product.id)
        .then(() => {
          if (parseInt(product.count, 10) === 0) {
            const itemIndex = this.items.findIndex(p => p.id === product.id)
            this.items.splice(itemIndex, 1)
            // Update count in cart items state
            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
          }
        })
    },
    removeProductFromCartAll(product) {
      this.$store.dispatch('app-ecommerce/removeProductFromCartAll', product.id)
        .then(() => {
          const itemIndex = this.items.findIndex(p => p.id === product.id)
          this.items.splice(itemIndex, 1)
          // Update count in cart items state
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', this.items.length)
        })
    },
    addProductInCart(product) {
      this.$store.dispatch('app-ecommerce/addProductInCart', product.id)
    },
    changeQtyItemInCart(val, item) {
      if (val === 'minus') {
        this.removeProductFromCart(item)
      }
      if (val === 'plus') {
        this.addProductInCart(item)
      }
    },
    changeTestMode(product) {
      if (product.testMode === '0') {
        this.$store.dispatch('app-ecommerce/addProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '1'
        })
      } else if (product.testMode === '1') {
        this.$store.dispatch('app-ecommerce/removeProductTestMode', product.id).then(() => {
          // eslint-disable-next-line
          product.testMode = '0'
        })
      }
    },
  },
  setup() {
    const server = process.env.VUE_APP_SERVER

    return {
      server,
      declension,
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
<style scoped>
.testMode >>> .custom-switch {
  min-height: 1rem!important;
}
.testMode >>> .custom-switch .custom-control-label {
  padding-left: 0!important;
}
</style>
